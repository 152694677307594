define("ember-select-light/components/select-light", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils", "@ember/debug"], function (_exports, _component, _templateFactory, _component2, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select
    {{on "change" this.change}}
    ...attributes>
    {{#if @placeholder}}
      <option value="" selected disabled>
        {{@placeholder}}
      </option>
    {{/if}}
  
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{#if this.hasDetailedOptions}}
        {{#each @options as | optionValue |}}
          <option
            value={{get optionValue this.valueKey}}
            selected={{is-equal (get optionValue this.valueKey) @value}}>
            {{get optionValue this.displayKey}}
          </option>
        {{/each}}
      {{else}}
        {{#each @options as | optionValue |}}
          <option value={{optionValue}} selected={{is-equal optionValue @value}}>
            {{optionValue}}
          </option>
        {{/each}}
      {{/if}}
    {{/if}}
  </select>
  
  */
  {
    "id": "sYy1Aadh",
    "block": "[[[11,\"select\"],[17,1],[4,[38,0],[\"change\",[30,0,[\"change\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"option\"],[14,2,\"\"],[14,\"selected\",\"\"],[14,\"disabled\",\"\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasDetailedOptions\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,3]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[28,[37,6],[[30,4],[30,0,[\"valueKey\"]]],null]],[15,\"selected\",[28,[37,7],[[28,[37,6],[[30,4],[30,0,[\"valueKey\"]]],null],[30,5]],null]],[12],[1,\"\\n          \"],[1,[28,[35,6],[[30,4],[30,0,[\"displayKey\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null]],[]],[[[42,[28,[37,5],[[28,[37,5],[[30,3]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[30,6]],[15,\"selected\",[28,[37,7],[[30,6],[30,5]],null]],[12],[1,\"\\n          \"],[1,[30,6]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[6]],null]],[]]]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@placeholder\",\"@options\",\"optionValue\",\"@value\",\"optionValue\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"get\",\"is-equal\"]]",
    "moduleName": "ember-select-light/components/select-light.hbs",
    "isStrictMode": false
  });
  const noop = () => {};
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component2.default {
    constructor() {
      super(...arguments);
      this.valueKey = this.args.valueKey ?? 'value';
      this.displayKey = this.args.displayKey ?? 'label';
      this.change = this.args.onChange ?? this.args.change ?? noop;
      (false && !(!this.args.change) && (0, _debug.deprecate)(`Triggering @change on <SelectLight /> is deprecated in favor of @onChange due to ember-template-lint's no-passed-in-event-handlers rule`, !this.args.change, {
        id: 'ember-select-light.no-passed-in-event-handlers',
        until: '3.0.0',
        for: 'ember-select-light',
        since: {
          enabled: '2.0.5'
        }
      }));
    }
    get hasDetailedOptions() {
      return ![
      // Returns a boolean if all data is available for a { label: foo, value: bar } style list of options
      this.args.options?.[0][this.valueKey], this.args.options?.[0][this.displayKey]].some(_utils.isNone);
    }
  });
});